import React, { useEffect, useState, useRef } from 'react';
import "./Home.css"
//import "./Header.css"
import { useDrag } from 'react-use-gesture';
import ImageSlider from '../ImageSlider ';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlay } from '@fortawesome/free-solid-svg-icons';
import homeSection2Video from '../../assets/video/02_Ketal_Show_Horizontal_V1_HIGH.mp4';
import homeSection1Video from '../../assets/video/Ketal es Niña YT 231127.mp4';
import deliveryWeb from '../../assets/image/delivery-web.png';
import videoFrame from '../../assets/image/video-frame.png';
import {useNavigate} from 'react-router-dom';
import VideoPopUp from '../VideoPopUp';
import video1 from '../../assets/image/MesaDeTrabajo1.png';
function Home() {
    const navigate = useNavigate();

    const UpdateCounter = async (id) => {
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(id)
        }
        fetch("api/ketalweb/visitCounter", requestOptions);
    }
    useEffect(() => {
        const id = 2;
        UpdateCounter(id);
    }, []);

    const redirectToSection = (v) => {
        const vh = Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
        );
        const scrollPosition = v;
        const scrollOffset = vh * (scrollPosition / 100);
        window.scrollTo({
            top: scrollOffset,
            behavior: 'smooth',
        });
    }

    const Section1 = () => {
        const [showVideo, setShowVideo] = useState(false);
        return (
            <>
                {showVideo === true &&
                    <VideoPopUp videoProp={homeSection1Video} onClose={() => setShowVideo(false)} />
                }
                <div className="home-section home-section-1">
                    <div className="home-section-1-card">
                        <span>Cambiamos <br /> antes de perderte</span>
                        <button onClick={e => setShowVideo(true)}>Ver más</button>
                    </div>
                </div>
            </>
        );
    }
    const Section2 = () => {
        const [showVideo, setShowVideo] = useState(false);
        return (
            <>
                {showVideo === true &&
                    <VideoPopUp videoProp={homeSection2Video} onClose={() => setShowVideo(false)} />
                }
                <div className="home-section home-section-2">
                    <h2>Conoce nuestra nueva sala</h2>
                    <div className="home-section-2-img">
                        <img src={videoFrame}>
                        </img>
                    </div>
                    <img src={videoFrame}
                        className="home-section-2-img-reflex">
                    </img>

                    <button className="home-section-2-video-play-btn" onClick={() => setShowVideo(true)}>
                        <FontAwesomeIcon icon={faCirclePlay} />
                    </button>
                </div>
            </>
        );
    }
    return (
        <React.Fragment>
            <Section1 />
            <Section2/>
            <div className="home-section home-section-3">
                <div className="home-section-3-card">
                    <span>El supermercado donde <br />
                        la calidad y el ahorro <br /> se encuentran</span>
                    <button onClick={ e => navigate("/ofertas")}>VER LAS <br /> OFERTAS</button>
                </div>
            </div>
            <div className="home-section home-section-4">
                <div className="home-section-4-img-cont">
                    <img src={deliveryWeb}
                        className="home-section-4-img" />
                    <button className="home-section-4-btn" onClick={ e => navigate("/pedidos")}>
                        Hacer <br /> un pedido</button>
                </div>
                <img src={deliveryWeb}
                    className="home-section-4-img-reflex" />
            </div>
            <div className="home-section home-section-5">
                <span className="home-section-5-title-1">Y tú</span>
                <span className="home-section-5-title-2">¿Ya eres parte de la <br />
                    Comunidad Ketal?</span>
                <button className="home-section-5-btn" onClick={e => { window.open('https://clientes.ketalviews.com.bo/', '_blank') } }>
                    REGÍSTRATE</button>
                <span className="home-section-5-title-3">Aprovecha todos los beneficios
                    que tenemos para ti</span>
            </div>
        </React.Fragment>
    );
}

export default Home;
