import React, { useState } from 'react';
import logo from '../../assets/image/KETAL_LOGO_2023-03.png';
import './Header.css';
import { useNavigate } from 'react-router-dom';

const Header = (props) => {
    const navigate = useNavigate();
    const [isNavOpen, setIsNavOpen] = useState(false);
    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };
    const tocaTusSenos = async () => {
        const id = 1;
        UpdateCounter(id);
        window.open('http://tocatussenos.com/', '_blank');
    }
    const UpdateCounter = async (id) => {
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(id)
        }
        fetch("api/ketalweb/visitCounter", requestOptions);
    }
    const redirectToSection = (v) => {
        const vh = Math.max(
            document.documentElement.clientHeight || 0,
            window.innerHeight || 0
        );
        const scrollPosition = v;
        const scrollOffset = vh * (scrollPosition / 100);
        window.scrollTo({
            top: scrollOffset,
            behavior: 'smooth',
        });
    }
    const whatsappDelivery = async () => {
        const id = 3;
        UpdateCounter(id);
        window.open('https://wa.me/59161875000', '_blank');
    }
    return (
        <header>
            <nav className={`navbarK ${isNavOpen ? 'open' : ''}`}>
                <a className="navbar-brand" onClick={e => navigate("/") }>
                    <img className="logo-ketal-sa" src={logo}
                        alt="Logo KETAL S.A" />
                </a>
                <div className="navbar-toggler" onClick={toggleNav}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div className="header-buttons">
                    <a className="menu-btn" onClick={ e => navigate("/") }>INICIO</a>
                    <a className="menu-btn" onClick={ e => navigate("/ofertas") }>OFERTAS</a>
                    <a className="menu-btn" onClick={ e => navigate("/pedidos") }>PEDIDOS</a>
                    {/*<a className="menu-btn">LISTA DE PRODUCTOS</a>*/}
                    <a className="menu-btn" href="https://clientes.ketalviews.com.bo/" target="_blank">REGÍSTRATE</a>
                </div>
                <ul className={`navbar-items-list ${isNavOpen ? 'showList' : 'hidde-list'}`}>
                    <li>
                        <a className="btn-font" onClick={e => navigate("/")}>INICIO</a></li>
                    <li>
                        <a className="btn-font" onClick={e => navigate("/ofertas")}>OFERTAS</a></li>
                    <li>
                        <a className="btn-font" onClick={e => navigate("/pedidos")}>PEDIDOS</a></li>
                </ul>
            </nav>
        </header>
    );
}

export default Header;