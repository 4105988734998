import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import Header from './Header';
import Footer from './Footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

function Layout({ children }) {
    const whatsappDelivery = async () => {
        const id = 3;
        UpdateCounter(id);
        window.open('https://wa.me/59161875000', '_blank');
    }
    const UpdateCounter = async (id) => {
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(id)
        }
        fetch("api/ketalweb/visitCounter", requestOptions);
    }

    return (
        <div className="layout-container">
            <Header></Header>
            <main className="home-section-container">
                <a className="whast-app-link" onClick={whatsappDelivery}>
                    <div className="whats-app-link-btn">
                        <FontAwesomeIcon icon={faWhatsapp} />
                    </div>
                </a>
                {children}
            </main>
            <Footer></Footer>
        </div>
    );
}

export default Layout;

