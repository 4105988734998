import React, { Component } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import Layout from './components/Layout';
import './index.css';
import Home from './components/Home/index';
import PayConirmation from './components/PayConfirmation/index';

function App() {
    const location = useLocation();
    return (
        <>
            {location.pathname !== '/payconfirmation' && (
                <Layout>
                    <Routes>
                        {AppRoutes.map((route, index) => {
                            const { element, ...rest } = route;
                            return <Route key={index} {...rest} element={element} />;
                        })}
                    </Routes>
                </Layout>)}
            <Routes>
                <Route path ="/payconfirmation" element={<PayConirmation/>}/>
            </Routes>
        </>
    );
}
export default App;
