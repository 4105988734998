import React, { useRef, useEffect} from 'react';
import './NotifyV.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircleXmark  } from '@fortawesome/free-solid-svg-icons';

library.add();
function NotifyV({ message, onClose }) {
    const notifyvContainerRef = useRef();
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            console.log("ESPERA Y EJECUCION");
            notifyvContainerRef.current.classList.add("notifyv-container-show");
        }, 200);
        return () => clearTimeout(timeoutId);
    });
    const close = () => {
        notifyvContainerRef.current.classList.remove("notifyv-container-show");
        setTimeout(() => {
            onClose();
        }, 300);
    }
    return (
        <div ref={notifyvContainerRef} className="notifyv-container" onClick={close}>
            <div className="notifyv-card" style={{ background: '#FFF' }}>
                <div className="notifyv-cont">
                    <button className="notifyv-close-btn" onClick={close}><FontAwesomeIcon icon={faCircleXmark} /></button>
                    {message}
                </div>
            </div>
        </div>
    );
}

export default NotifyV;