import  { useState,  useEffect } from 'react';

export function useFetchRep( ){
    const [data , setData]= useState([]);
    const [loading , setLoading]= useState(true);

    const getReport= async (giftCard)=>{
        
        setLoading(true);
        const requOption   = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                numeroGIFTCard: giftCard
              
            })
            
        }
        var url ="/api/giftCard" //"/cliLoc" 
        
        
        setLoading(true)
        
        
            const response =await fetch(url,requOption)
            //.then((response)=>response.json())
            //.then((data)=>setData(data))
            //.finally(()=>setLoading(false))
            //console.log(loading)
            const data = await response.json();
            setLoading(false)
            return [data,loading];
            //setData(data)
        //} 
        //inicio();
        
        //.finally(()=>setLoading(false));
    }
    useEffect (()=>{
        getReport() 

    }
        , []);
    return { getReport};
}