import './TerminosDelivery.css';

const TerminosDelivery = () => {
    return(
        <div className="terminos-container">
            <h1 className="text-align-justify">Términos y condiciones<u></u><u></u></h1>
            <p className="text-align-justify">Los términos y condiciones se aplicarán para la navegación del sitio de internet
            <a href="http://www.sure.com.bo/" target="_blank">www.ketal.com.bo</a> (en adelante el &quot;Website&quot; cuando se refiera al sitio web de internet) y los servicios ofrecidos en él)<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">1 - Aceptación y conocimiento de los Términos y Condiciones</span></b><u></u><u></u></p>
            <p className="text-align-justify">Los presentes Términos y Condiciones tienen carácter obligatorio y vinculante. Incluye la navegación, registro y uso de la web como de los servicios que se ofrece. Se aplican a todas las compras y actividades realizadas mediante
            el Website. El uso del sitio implica el conocimiento y la aceptación de ellos. Si usted no está de acuerdo con los Términos y Condiciones, deberá abstenerse de utilizar el Website y/o los servicios ofrecidos.<u></u><u></u></p>
            <p className="text-align-justify">&nbsp;Por &quot;Usuario&quot; del Website se entiende tanto a los registrados como a los visitantes.<u></u><u></u></p>
            <p className="text-align-justify">Para permitirles a los Usuarios una más rápida familiarización con estos Términos y Condiciones podrán consultar también todas las demás instrucciones para operar en el Website, como por ejemplo, la página de ayuda que también
            forman parte de estos Términos y Condiciones.<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">2 - Modificación de los Términos y Condiciones</span></b><u></u><u></u></p>
            <p className="text-align-justify">Los Términos y Condiciones podrán ser sustituidos o sufrir modificaciones en cualquier momento y a exclusivo criterio de KETAL S.A. y no se requerirá a los Usuarios su consentimiento. Bastará su publicación en la web para ser aplicables
            a todos los usuarios, situación que se reputará como notificación formal y suficiente. Para las transacciones en curso que hayan comenzado con anterioridad a dichas modificaciones, subsistirán las condiciones vigentes al momento de su concertación, a menos
            que las nuevas modificaciones introducidas fueran más convenientes para el Usuario.<u></u><u></u></p>
            <p className="text-align-justify">KETAL.S.A. Pondrá un aviso en el Website alertando a los Usuarios sobre estos cambios, durante un tiempo razonable. Sin perjuicio de lo anterior, los Usuarios son responsables de leer estos Términos y Condiciones cada vez que realicen
            transacciones en el website.<u></u><u></u></p>
            <p className="text-align-justify">&nbsp;<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">3 - Interrupción del Servicio - Exclusión de responsabilidad.</span></b><u></u><u></u></p>
            <p className="text-align-justify">KETAL S.A. se reserva el derecho de interrumpir, suspender o modificar en cualquier momento los servicios ofrecidos en el presente Website, ya sea en forma permanente o transitoria. No se requerirá la conformidad de los Usuarios,
            ni será necesario aviso previo alguno.<u></u><u></u></p>
            <p className="text-align-justify">Asimismo, KETAL S.A. No garantiza el acceso o uso permanente del Website, ya que éste podría interrumpirse por cuestiones técnicas ajenas a KETAL S.A. No obstante, si la suspensión o interrupción mencionada no obedeciere a razones
            de fuerza mayor o caso fortuito, KETAL S.A. se compromete a cumplir las prestaciones que estuvieran pendientes al momento de la suspensión o interrupción.<u></u><u></u></p>
            <p className="text-align-justify">KETAL S.A. no garantiza que el Website se encuentre libre de virus, gusanos o cualquier otro elemento que pueda llegar a dañar o alterar el normal funcionamiento de un ordenador. Es responsabilidad y obligación exclusiva del Usuario
            contar con las herramientas adecuadas para detectar, desinfectar y/o prevenir cualquier tipo de elementos y/o posibles daños de esta naturaleza.<u></u><u></u></p>
            <p className="text-align-justify">KETAL S.A. no se responsabiliza por cualquier daño que pueda producirse en los equipos informáticos de los Usuarios o de terceros como consecuencia de la navegación del presente Website.<u></u><u></u></p>
            <p className="text-align-justify">Queda expresamente advertido y por usted reconocido que KETAL no será responsable, frente a usted como así tampoco frente a ningún tercero, por ninguna modificación, suspensión o interrupción del Servicio.<u></u><u></u></p>
            <p className="text-align-justify">KETAL S.A. no será responsable de ningún daño directo, indirecto, incidental, especial, emergente o punitivo, incluyendo, aunque no en forma taxativa, ningún daño por lucro cesante, pérdida de buen nombre, uso, datos u otro tipo
            de pérdidas intangibles (Aún si KETAL S.A. hubiese sido advertida de la posibilidad de estos daños), que fueren consecuencia de la utilización del servicio por parte suya.<u></u><u></u></p>
            <p className="text-align-justify">&nbsp;<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">4 – Registro de Usuarios</span></b><u></u><u></u></p>
            <p className="text-align-justify">Los Usuarios pueden navegar libremente por el Website, pero deberán estar registrados para poder realizar alguna compra o utilizar los servicios ofrecidos en él.<u></u><u></u></p>
            <p className="text-align-justify">El registro de los Usuarios se realiza ingresando al Website, y no tiene costo alguno.<u></u><u></u></p>
            <p className="text-align-justify">Es obligatorio completar el formulario en todos los campos con datos válidos y verdaderos, de manera exacta y precisa. Para un correcto funcionamiento del sistema, es necesario que los Usuarios mantengan sus datos actualizados.
            KETAL S.A. Podrá proceder a verificar la identidad del Usuario y/o de los datos consignados por éste.<u></u><u></u></p>
            <p className="text-align-justify">KETAL S.A. no se responsabiliza por la veracidad o certeza de los datos provistos por los Usuarios. Asimismo, KETAL S.A. se reserva el derecho de suspender temporal o definitivamente a los Usuarios en caso de incumplimiento de
            los Términos y Condiciones, como así también de rechazar solicitudes.<u></u><u></u></p>
            <p className="text-align-justify">Los Usuarios accederán a su Cuenta Personal (la &quot;Cuenta&quot;) mediante un nombre de Usuario y una clave personal que deberán escoger. En caso de que estos datos sean olvidados por el Usuario, KETAL S.A. cuenta con un servicio de ayuda
            para recuperarlos. Para esto se deberá hacer &quot;click&quot; en la opción correspondiente e ingresar el e-mail que se proporcionó al momento del registro. Al finalizar, el sistema le enviará de manera confidencial a esa cuenta su nombre de Usuario y la clave personal.
            En caso de que el software utilizado tenga algún error podrá solicitar un usuario al servicio de soporte de Ketal S.A. que se tuviera habilitado, o mediante contacto telefónico al área de sistemas de la empresa.<u></u><u></u></p>
            <p className="text-align-justify">Así como KETAL S.A. se compromete a mantener la confidencialidad de los datos aportados por los Usuarios para su registro, los Usuarios se comprometen a mantener la confidencialidad de su nombre y clave de acceso. De conformidad
            con lo expresado en el párrafo anterior, los Usuarios son responsables del uso que se haga de su clave y deberán tener presente que si otras personas tienen o pueden tener en el futuro acceso a la cuenta de e-mail consignada como propia en el formulario de
            registro, éstas también podrían solicitar su clave de acceso y nombre de Usuario. Es obligación exclusiva del Usuario tomar las medidas pertinentes para que esto no suceda. El Usuario se compromete a notificar inmediatamente y de manera fehaciente a KETAL
            S.A. cualquier uso no autorizado de su Cuenta de Usuario, y a mantenerlo indemne en el caso de que se esto produzca algún daño a la Empresa o a terceros.<u></u><u></u></p>
            <h3 className="text-align-justify">Usos Prohibidos<u></u><u></u></h3>
            <p className="text-align-justify">Usted se obliga a no realizar cualquiera de los siguientes actos:<u></u><u></u></p>
            <ol start="1" type="1">
            <li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp;&nbsp;Suplantar la identidad de alguna persona;&nbsp;<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp; Violar alguna ley departamental o nacional a través del servicio mismo de la página web;&nbsp;<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp;&nbsp;Causar molestias o perturbar a terceros a través del, o en el Servicio;&nbsp;<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp; Recopilar o almacenar datos de terceros o acerca de ellos;&nbsp;<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp;&nbsp;Utilizar algún aparato, software o rutina para interferir o intentar interferir en el funcionamiento adecuado del Servicio;<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp;&nbsp;Tomar alguna medida que implique una carga irrazonable o desproporcionadamente grande sobre la infraestructura del Servicio;<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp; Utilizar el Servicio de alguna forma que no sea según lo expresamente autorizado en Los Términos y Condiciones;<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp;&nbsp;Utilizar algún robot, spider, algún otro dispositivo automático, o proceso manual para controlar o copiar algún contenido del Servicio;<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp;&nbsp;Reproducir, duplicar, copiar, vender, revender o explotar, con o sin algún fin comercial, alguna parte del Servicio, uso del Servicio o acceso al Servicio.<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp;Utilizar el Servicio para publicitar sus productos o Servicios, enviar correos electrónicos en cadena o para realizar la distribución masiva de correo no solicitado; o&nbsp;<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp;Utilizar términos o expresiones injuriosas, intimidatorios, calumniantes o contrarias a las buenas costumbres, o transmitir información o material que pueda, concreta o eventualmente, violar derechos de un tercero o que contenga virus o cualquier otro componente
            dañino.<u></u><u></u></li></ol>
            <p className="text-align-justify"><b><span className="fuente-calibri">&nbsp;</span></b><u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">5 - Capacidad</span></b><u></u><u></u></p>
            <p className="text-align-justify">Usted declara y garantiza a KETAL S.A.: (a) que tiene plenos poderes y facultades, así como también la capacidad legal para celebrar Contratos y cumplir las obligaciones derivadas de los mismos, y que si se está registrando en
            representación de una compañía o de alguna otra entidad, que tiene la facultad de obligar a su mandante o a la compañía que lo emplea; (b) que le brindará información completa y precisa a KETAL S.A., incluyendo, aunque no en forma taxativa, sus recomendaciones
            o requerimientos. Sin embargo, KETAL S.A. se reserva el derecho de verificar y constatar su capacidad legal para celebrar contratos y adquirir obligaciones; si en dicha verificación, se constata que usted con cuenta con la capacidad señalada, KETAL S.A. podrá
            tomar las medidas legales que considere pertinentes.<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">&nbsp;</span></b><u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">6 - Política de Privacidad de los datos personales suministrados por el Usuario. Seguridad y tratamiento</span></b><u></u><u></u></p>
            <p className="text-align-justify">Para poder utilizar el Website de manera eficiente y segura, los Usuarios deberán aportar ciertos datos, entre ellos, su nombre y apellido, domicilio, cuenta de e-mail, documento de identidad, sin los cuales se tornaría imposible
            brindar los servicios. Por eso se requiere que éstos sean verdaderos y exactos. Los datos recabados por los formularios correspondientes serán incorporados a una base de la cual es responsable KETAL S.A. La información personal que los Usuarios ingresan en
            el Website es totalmente confidencial y KETAL S.A. hará su mejor esfuerzo para proteger la privacidad de los mismos. No obstante lo anterior, el Usuario deberá tener en cuenta que Internet no es un medio inexpugnable en cuanto a su seguridad.<u></u><u></u></p>
            <p className="text-align-justify">Los Usuarios tienen el derecho de acceder a la información de su Cuenta, y podrán modificar los datos ingresados cuando lo deseen. Asimismo, los Usuarios podrán requerir en cualquier momento la baja de su solicitud y la eliminación
            de su Cuenta de la base de datos.<u></u><u></u></p>
            <p className="text-align-justify">De acuerdo con la autorización otorgada por usted al inicio de la transacción, es decir al momento de entregar sus datos personales, KETAL S.A. podrá utilizar dicha información de conformidad con la finalidad por usted autorizada
            en el mencionado documento. Adicionalmente a lo autorizado por usted, su información podrá ser remitida a las autoridades pertinentes, cuando ésta sea requerida por la ley o por acciones legales (por ejemplo, de acuerdo a una orden judicial o para propósitos
            de prevención de delito o fraude).<u></u><u></u></p>
            <p className="text-align-justify">&nbsp;<u></u><u></u></p>
            <p className="text-align-justify">Por el sólo hecho de registrarse en el Website, los Usuarios aceptan que KETAL S.A. tiene derecho a comunicarse con ellos por vía postal, telefónica o electrónica y enviar información que la Empresa considere, a su exclusivo criterio,
            que pueda ser de su interés, incluyendo publicidad e información sobre ofertas y promociones. En caso de que los Usuarios no deseen ser contactados con estos fines, podrán manifestárselo fehacientemente a KETAL S.A. quien procederá a interrumpir este tipo
            de comunicaciones en el menor tiempo que le sea posible.<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">7 - Veracidad de la Información suministrada</span></b><u></u><u></u></p>
            <p className="text-align-justify">En caso de que la información o los datos suministrados por el Usuario no sean verdaderos, éste será responsable por los daños que este hecho pudiera ocasionar.<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">8 -Sugerencias y comentarios</span></b><u></u><u></u></p>
            <p className="text-align-justify">Todas las sugerencias y comentarios que realice el Usuario podrán ser tenidos en cuenta, implementados o adaptados por KETAL S.A. sin que ello genere derecho alguno a favor de tal Usuario.<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">9 - Cookies</span></b><u></u><u></u></p>
            <p className="text-align-justify">El Website puede utilizar un sistema de seguimiento mediante &quot;cookies&quot;, para que el acceso a la información, al pasar de página en página, se realice con mayor rapidez. También ayuda en algunos casos a identificar a los Usuarios,
            sin necesidad de solicitarles la clave de acceso una y otra vez.<u></u><u></u></p>
            <p className="text-align-justify">Estas cookies son pequeños archivos que envía la página visitada y se alojan en el disco duro del ordenador, ocupando poco espacio.<u></u><u></u></p>
            <p className="text-align-justify">Se hace saber a los Usuarios que utilizando las opciones de su navegador podrán limitar o restringir según su voluntad el alojamiento de estas &quot;cookies&quot;, aunque es desaconsejable restringirlas totalmente.<u></u><u></u></p>
            <p className="text-align-justify">El sistema podrá recoger información sobre sus preferencias e intereses. En el caso de que esto ocurra, la información será utilizada exclusivamente con fines estadísticos para mejorar los servicios que se prestan en el Website.
            KETAL S.A. aplicará, en la mayor medida en que sea posible, procedimientos de disociación de la información de modo que los titulares de los datos sean inidentificables.<u></u><u></u></p>
            <p className="text-align-justify">El Servicio puede brindar enlaces a sitios de terceros. Usted reconoce que KETAL S.A. no tiene control alguno sobre esos sitios y no es responsable por su disponibilidad, no los respalda, y no es responsable por el contenido, las
            mercaderías o los Servicios disponibles en esos sitios. Recomendamos que verifique la política de cada sitio que usted visita. KETAL S.A. no será responsable, en forma directa o indirecta, por ningún daño o pérdida causados por el uso de otros sitios o por
            la confianza depositada en ningún contenido, mercadería o Servicio disponible a través de los sitios cuyos enlaces se encuentren en la página web de KETAL S.A. KETAL S.A. prohíbe el uso de los nombres y/o logotipos, marcas y cualesquiera otros signos distintivos
            de su propiedad como enlaces hipertextuales o de cualquier otra forma (&quot;links”) dirigidos a sitios en Internet cuyo URL sea diferente de&nbsp;<a href="http://www.ketal.com.bo/" target="_blank">http://www.ketal.com.bo</a>, a menos que el establecimiento de un enlace de tal naturaleza
            sea aprobado por KETAL S.A. por escrito en cuyo caso deberá atenerse a los criterios y manuales de diseño y publicidad establecidos por KETAL S.A. KETAL S.A. se reserva el derecho de solicitar el retiro de enlaces que hayan sido establecidos en sitios web
            sin su expresa y previa autorización. El establecimiento en páginas no controladas por KETAL S.A. de enlaces a subdirectorios dentro del URL&nbsp;<a href="http://www.ketal.com.bo/" target="_blank">http://www.ketal.com.bo</a>&nbsp;(“Deeplinking”) queda prohibido. El despliegue, uso,
            exhibición, copia o cualquier otra forma de reproducción del sitio&nbsp;<a href="http://www.ketal.com.bo/" target="_blank">http://www.ketal.com.bo</a>&nbsp;o de cualquiera de sus subdirectorios y/o páginas, en sitios no controlados por KETAL S.A. (“Framing”) queda expresamente prohibido.
            La inobservancia de estas prohibiciones será una violación a los derechos de propiedad intelectual sobre los contenidos y a los derechos sobre la Propiedad Industrial.<u></u><u></u></p>
            <p className="text-align-justify">&nbsp;<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">10 - Disponibilidad y precio de los productos ofrecidos</span></b><u></u><u></u></p>
            <p className="text-align-justify">&nbsp;Antes de comprar, el Usuario deberá tener en cuenta que los productos seleccionados pueden no encontrarse en stock. Toda compra se encuentra sujeta a disponibilidad. Por este motivo, puede ocurrir que, por más que sea posible
            ordenar la compra del producto en el Website, no haya existencias de éste, por una cuestión de movimiento diario de las firmas proveedoras de&nbsp;KETAL S.A.<u></u><u></u></p>
            <p className="text-align-justify">Ninguna oferta obliga a KETAL S.A. sino hasta la aceptación expresa de la misma.<u></u><u></u></p>
            <p className="text-align-justify">KETAL S.A. puede, a su exclusivo criterio, limitar o cancelar las cantidades compradas por persona. Además, KETAL S.A. se reserva el derecho de rechazar cualquier pedido que usted realice. Son restricciones que pueden incluir pedidos
            realizados por la misma cuenta del sitio web de KETAL S.A., la misma tarjeta de crédito y pedidos con la misma dirección de envío o de facturación.<u></u><u></u></p>
            <p className="text-align-justify">&nbsp;<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">11 - Opciones del Usuario ante productos agotados o demorados</span></b><u></u><u></u></p>
            <p className="text-align-justify">En concordancia con el punto anterior, en caso de que el o los productos seleccionados se encontraren agotados o demorados, KETAL S.A. se comunicará con el Usuario y lo invitará a que elija una de las siguientes opciones:<u></u><u></u></p>
            <ul type="disc">
            <li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Continuar esperando la entrega del producto elegido (en caso de demora);<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Cancelación de la compra y devolución del importe por el medio de pago original;<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Optar por un producto alternativo que le ofrezca KETAL S.A. para el caso particular;<u></u><u></u></li></ul>
            <p className="text-align-justify">Cuando el Usuario opte por cancelar la compra, se devolverá el importe abonado según el medio de pago que se haya elegido oportunamente.
            <u></u><u></u></p>
            <p className="text-align-justify">En el caso de que el Usuario opte por el producto alternativo que le ofrece KETAL S.A. éste deberá contener características iguales o superiores. En ningún caso se le pedirá al Usuario que abone sumas suplementarias o que se haga
            cargo de las diferencias, salvo que el producto alternativo ofrecido por KETAL S.A. y escogido por el Usuario sea de características ampliamente superiores y costosas al solicitado originalmente por el Usuario.<u></u><u></u></p>
            <p className="text-align-justify">IMPORTANTE: para las situaciones contempladas en este apartado, el Usuario tendrá un plazo de 1 día para elegir una de las opciones mencionadas. En caso de que el Usuario guarde silencio al respecto, KETAL S.A. podrá presumir que
            ha optado por la cancelación de la compra, y procederá a la devolución del importe abonado, ya sea por el medio de pago original.<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">12 - Devolución del importe abonado</span></b><u></u><u></u></p>
            <p className="text-align-justify">En los casos mencionados en el punto anterior en que el Usuario haya optado por la devolución del importe abonado, deberá tener en cuenta que el reintegro puede demorar algunos días, debido a plazos y cuestiones administrativas.<u></u><u></u></p>
            <p className="text-align-justify">Para los casos de devolución vía depósito bancario, la cuenta bancaria deberá estar a nombre del titular de la Cuenta de Usuario desde donde se haya realizado la operación. En caso de que no coincidan las titularidades, se requerirá
            la expresa autorización del titular de la Cuenta de Usuario como condición indispensable previa al depósito. Todo costo asociado a la devolución del monto corre por cuenta del Usuario.<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">13 - Validez de las promociones</span></b><u></u><u></u></p>
            <p className="text-align-justify">En el caso de que se realicen ofertas y promociones de productos, éstas tendrán validez para las compras efectuadas desde la fecha de inicio de las mismas, hasta la de finalización de la oferta. Los términos y condiciones de las
            mismas serán comunicados en el Website, y estarán siempre sujetas a la existencia en stock de los productos ofrecidos.<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">14 - Impuesto al valor agregado (IVA)</span></b><u></u><u></u></p>
            <p className="text-align-justify">Todos los precios expresados en el Website incluyen IVA, salvo que se indique lo contrario.<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">15 - Garantía de los productos</span></b><u></u><u></u></p>
            <p className="text-align-justify">El sitio web de KETAL S.A. apunta a exhibir imágenes de productos con la mayor precisión posible. No obstante, no podemos garantizar que el color que usted ve coincida con el color del producto, ya que la visualización del color
            depende, en parte, del monitor que utilice.<u></u><u></u></p>
            <p className="text-align-justify">La utilización por parte suya del servicio es a su exclusivo riesgo. El servicio se brinda &quot;en el estado en que se encuentra&quot; y &quot;en la medida que está disponible&quot;. Usted reconoce y acepta que KETAL S.A. expresamente se exime de
            toda garantía de cualquier tipo en relación con los servicios ofrecidos en la página web de KETAL S.A., ya sea expresa o implícitamente. Todo material o dato descargado u obtenido de cualquier otro modo a través del uso del servicio es efectuado a discreción
            y riesgo propio, y usted será responsable en forma exclusiva por todo daño o pérdida que pudiera resultar de la utilización del servicio por parte suya. Ninguna recomendación o información, ya fuese verbal o escrita, que usted hubiese obtenido de KETAL S.A.
            a través del servicio, generará ninguna garantía que no esté expresamente estipulada en los términos y condiciones. Las condiciones referentes a la garantía de los bienes adquiridos en la página web de KETAL S.A. se someterán a lo dispuesto en la normatividad
            vigente.<u></u><u></u></p>
            <p className="text-align-justify">&nbsp;<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">16 - Derecho de arrepentimiento. Devolución de los productos</span></b><u></u><u></u></p>
            <p className="text-align-justify">El Usuario tendrá derecho a devolver los productos adquiridos en el Website durante el plazo de 1 día corridos posteriores a la adquisición de los productos, contados a partir de la entrega del producto, sin responsabilidad alguna.
            Para esto deberá notificar de manera fehaciente a KETAL S.A., dentro del plazo señalado, y poner a su disposición el o los productos adquiridos. Los productos deberán estar en el mismo estado en que fueron recibidos, sin haber sido utilizados, y con el embalaje
            original. KETAL S.A. devolverá al Usuario todos los importes recibidos. Los gastos por devolución correrán por cuenta y orden del cliente, en caso de no conformidad o error del mismo.<u></u><u></u></p>
            <p className="text-align-justify">Asimismo, si el o los productos presentaren algún defecto de fabricación, hubieren sufrido roturas o deterioros en tránsito, o fueren despachados equivocadamente, el Usuario deberá comunicarse con el Centro de Atención al Cliente
            dentro de los 1 días corridos posteriores a la adquisición del producto, para que KETAL S.A. proceda, según corresponda, a gestionar el re-despacho de la compra, o pasar a retirar la orden. Los gastos de devolución al cliente por defectos o fallas de origen
            corren por cuenta y orden de KETAL S.A.<u></u><u></u></p>
            <p className="text-align-justify">Los productos no deben haber sido utilizados, y deben encontrarse en las mismas condiciones en que fueron recibidos, con sus embalajes y etiquetas.
            <u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">17 - Moneda</span></b><u></u><u></u></p>
            <p className="text-align-justify">Todos los precios en el Website están expresados en pesos bolivianos.<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">18 - Medios de pago
            </span></b><u></u><u></u></p>
            <p className="text-align-justify">Los pagos podrán realizarse a través de los siguientes métodos:<u></u><u></u></p>
            <ul type="disc">
            <li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pago en efectivo.<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Pago vía “Khipu”.<u></u><u></u></li><li class="MsoNormal" className="text-align-justify">
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Pago vía POS móvil.<u></u><u></u></li></ul>
            <p className="text-align-justify">&nbsp;<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">19 - Envío de productos Tiempo de entrega (ciudad de La Paz)</span></b><u></u><u></u></p>
            <p className="text-align-justify">Las entregas se realizarán en la dirección que el Usuario indique. La validez de la misma es de su exclusiva responsabilidad.<u></u><u></u></p>
            <p className="text-align-justify">El tiempo de entrega depende de la disponibilidad del producto, del tiempo de envío y de la aprobación del medio de pago.
            <u></u><u></u></p>
            <p className="text-align-justify">Al realizar una compra, el Usuario recibe en su casilla de correo electrónico una confirmación de que la orden de pedido ha sido aceptada, junto a un número de pedido.<u></u><u></u></p>
            <p className="text-align-justify">El tiempo de aprobación varía según el medio de pago.<u></u><u></u></p>
            <p className="text-align-justify">El tiempo de envío varía según el destino, donde se solicite la entrega.<u></u><u></u></p>
            <p className="text-align-justify">El tiempo de entrega de la totalidad de la orden, así como su costo, será informado al Usuario antes de enviar el producto.<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">20 - Gastos de envío</span></b><u></u><u></u></p>
            <p className="text-align-justify">El Usuario será claramente informado de los costos de entrega antes de realizar la compra. Estos costos son calculados en función del peso total y/o el volumen total del envío, y también dependen de la zona del domicilio de entrega.<u></u><u></u></p>
            <p className="text-align-justify">Los costos de envío serán discriminados como ítem separado dentro de la factura. KETAL S.A. está siempre trabajando para mejorar la calidad y el costo de entrega para sus clientes. Por este motivo, dichos costos y las políticas
            de envío se hallan sujetos a cambio sin previo aviso.<u></u><u></u></p>
            <p className="text-align-justify">&nbsp;<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">21 - Horarios de entrega</span></b><u></u><u></u></p>
            <p className="text-align-justify">Los productos serán entregados de lunes a viernes, entre las 9:00 y las 18:00 horas, con excepción de los feriados nacionales. Los Usuarios no podrán elegir ni el horario ni el día en que se entregarán el o los productos adquiridos,
            por lo que se recomienda seleccionar su domicilio laboral como punto de entrega para mayor seguridad. De todos modos, podrán hacerse sugerencias en el campo de observaciones al momento de realizar la compra, las que quedarán supeditadas al circuito de la empresa
            que tenga a su cargo el envío de los productos.<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">22 - Dirección de la entrega</span></b><u></u><u></u></p>
            <p className="text-align-justify">La dirección en donde se entregará el producto será la que el Usuario indique. Podrá no coincidir con su domicilio. Es responsabilidad del Usuario completar y revisar cuidadosamente la información relacionada con la entrega, para
            que el envío de la compra se haga de manera efectiva y puntual. No se realizan envíos a casillas de correo (P.O. Box)<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">23 – Facturación
            </span></b><u></u><u></u></p>
            <p className="text-align-justify">El Usuario recibirá junto al envío una factura con la información clara y precisa acerca de los productos comprados, el método y plan de pago, y la moneda para la transacción. En la parte inferior de la factura, el Usuario deberá
            prestar su conformidad, completando un recuadro con su firma, aclaración y número de documento.<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">24 - Cancelación de órdenes de compra</span></b><u></u><u></u></p>
            <p className="text-align-justify">Sin perjuicio de lo establecido en el punto 16, el Usuario podrá cancelar una orden, siempre y cuando ésta no haya sido aún despachada. Si la cancelación de la compra es total, se reintegrará el importe mediante el medio de pago
            que se utilizó para abonar, o se le podrá dejar como crédito para realizar la compra de otro producto. El Usuario reconoce que en este caso, KETAL S.A. Deberá realizar otro envío, motivo por el cual deberá cobrar al usuario los costos producidos por esta modificación.<u></u><u></u></p>
            <p className="text-align-justify">&nbsp;<u></u><u></u></p>
            <p className="text-align-justify"><b><span className="fuente-calibri">25.- Información De Marca Registrada</span></b><u></u><u></u></p>
            <p className="text-align-justify">KETAL S.A., el logotipo de KETAL y/o KETAL S.A., así como otros logotipos, productos y nombres de Servicios de KETAL S.A., son marcas registradas de KETAL S.A.. Usted no podrá exhibirlos o utilizarlos de forma alguna o de forma
            que se haga un uso comercial o engañoso (adoptar una falsa identidad) de ellos.<u></u><u></u></p>
            <p className="text-align-justify">&nbsp;<u></u><u></u></p>
            <p className="text-align-justify">&nbsp;<u></u><u></u></p>
        </div>
    );
}

export default TerminosDelivery;