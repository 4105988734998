import React from 'react';
import './Pedidos.css';
import bolsaImg from '../../assets/image/bolsa.png';
import whatsappIcon from '../../assets/image/whatsapp.png';
import peyaIcon from '../../assets/image/peya.png';
import googlePlayBadge from '../../assets/image/google-play-badge.png';
import appStoreBadge from '../../assets/image/app-store.png';
import ImageLoader from '../ImageLoader';

const Pedidos = () => {
    return (
        <React.Fragment>
            <div className="pedidos-section-1">
                <img src={bolsaImg} className="pedidos-section-1-img-bolsa"></img>
                <div className="pedidos-section-1-card-container">
                    <div className="pedidos-section-1-card">
                        <div className="pedidos-section-1-card-sec">
                            <img className="pedidos-section-1-app-icon" src={whatsappIcon}></img>
                            <button className="pedidos-section-1-store-btn"
                                onClick={() => {
                                    window.open("https://play.google.com/store/apps/details?id=com.whatsapp&pcampaignid=web_share", "_blank")
                                }}>
                                <img src={googlePlayBadge}></img></button>
                            <button className="pedidos-section-1-store-btn"
                                onClick={() => {
                                    window.open("https://apps.apple.com/es/app/whatsapp-messenger/id310633997", "_blank")
                                }}>
                            <img src={appStoreBadge}></img></button>
                        </div>
                        <div className="pedidos-section-1-btn-pedir">
                            <span>PIDE POR WHATSAPP!</span>
                            <button onClick={() => window.open("https://wa.me/59161875000", "_blank")}>PEDIR</button>
                        </div>
                    </div>
                    <div className="pedidos-section-1-card">
                        <div className="pedidos-section-1-card-sec">
                            <img className="pedidos-section-1-app-icon" src={peyaIcon}></img>
                            <button className="pedidos-section-1-store-btn" onClick={() => {
                                window.open("https://play.google.com/store/apps/details?id=com.pedidosya&pcampaignid=web_share", "_blank")
                            }}>
                                <img src={googlePlayBadge}></img></button>
                            <button className="pedidos-section-1-store-btn" onClick={() => {
                                window.open("https://apps.apple.com/es/app/pedidosya-delivery-app/id490099807", "_blank")
                            }}>
                                <img src={appStoreBadge}></img>
                            </button>
                        </div>
                        <div className="pedidos-section-1-btn-pedir">
                            <span>ENCUÉNTRANOS POR WHATSAPP!</span>
                            <button onClick={() => window.open("https://www.pedidosya.com.bo/restaurantes/la-paz/ketal--calacoto-4c9a11c5-2cc4-4eb8-8a93-723fcc0dd834-menu?origin=shop_list", "_blank")}>PEDIR</button>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default Pedidos;