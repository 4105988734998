import React, { useRef, useEffect, useState } from 'react'; 
import './VideoPopUp.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const VideoPopUp = ({ videoProp, onClose }) => {
    const videoRef = useRef();
    const closeBtnRef = useRef();
    const [showBtn, setShowBtn] = useState(false);
    const [showVideo, setShowVideo] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowBtn(true);
        }, 3000);
        setTimeout(() => {
            setShowVideo(true);
        }, 1000);
        setShowBtn(true);
        setShowVideo(true);
        const handleOutsideClick = (event) => {
            if (videoRef.current && !videoRef.current.contains(event.target)) {
                onClose();
            }
        };
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {  
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    return (
        <div className="video-container">   
            <div className="video-section" ref={ closeBtnRef }>
                <span className={`video-close-btn ${showBtn ? 'show': ''}`} onClick={() => onClose()}><FontAwesomeIcon icon={faCircleXmark} /></span>
                <video autoPlay muted loop ref={videoRef}
                    className={`video-style ${showVideo ? 'show' : ''}`}>
                    <source src={videoProp} type="video/mp4" />
                    Tu navegador no soporta el formato de video :C
                </video>
            </div>
        </div>
    );
}

export default VideoPopUp;