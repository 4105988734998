import React, { useState, useEffect } from 'react';
import './Ofertas.css';
import oferta1 from '../../assets/image/Papa-web.jpg';
import oferta2 from '../../assets/image/nino-web.png';
import oferta3 from '../../assets/image/Unikos.png';
import oferta4 from '../../assets/image/novedades-web.png';
import tocatussenos from '../../assets/image/TocaTusSenos.jpg';
import ketalVideo from '../../assets/video/ketal.mp4';
import unikos from '../../assets/video/unikos 1920x1080.mp4';
import VideoPopUp from '../VideoPopUp';

const Ofertas = () => {
    var closePopup = function () {
        var id = 'publuu-viewer-popup';

        var popup = document.getElementById(id);
        if (!popup) {

            popup = document.createElement('div');
            popup.id = id;
            popup.style.cssText = 'visibility:hidden;z-index:99999;position:fixed;top:0;right:0;bottom:0;left:0;background:rgba(0, 0, 0, 0.7);opacity:0;transition:all 500ms ease-in-out;';

            var internalElem = document.createElement('div');
            internalElem.style.cssText = 'position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);background:#fff;border-radius:5px;width:80%;max-width:1345px;height:90%;max-height:850px;padding:0px;';
            popup.appendChild(internalElem);

            var closeBtn = document.createElement('a');
            closeBtn.href = '';
            closeBtn.innerHTML = '&times;';
            closeBtn.style.cssText = '    position: absolute; top: -10px; right: -10px;    text-decoration: none;    width: 24px;    height: 24px;    font-size: 19px;    font-weight: 700;    color: rgb(0 0 0);    border-radius: 50%;    background: white;    text-align: center;    line-height: 24px;    padding-left: 1px;';
            internalElem.appendChild(closeBtn);

            closeBtn.addEventListener('click', function (event) {
                event.preventDefault();

                closePopup();
            });

            var iframe = document.createElement('iframe');
            iframe.setAttribute('allowFullScreen', '');
            iframe.allow = 'clipboard-write';

            iframe.style.cssText = 'width:100%;height:100%;border:0;border-radius: 5px;';
            internalElem.appendChild(iframe);

            document.body.appendChild(popup);
        }

        var iframe = popup.getElementsByTagName('iframe')[0];

        popup.style.visibility = 'hidden';
        popup.style.opacity = '0';

        if (!iframe) return;

        setTimeout(() => iframe.src += `${iframe.src.indexOf('?') === -1 ? '?' : '&'}t=${Date.now()}`, 250);
        setTimeout(() => iframe.src = '', 500);
    };

    var showPopup = function (url) {
        var id = 'publuu-viewer-popup';

        var popup = document.getElementById(id);
        if (!popup) {

            popup = document.createElement('div');
            popup.id = id;
            popup.style.cssText = 'visibility:hidden;z-index:99999;position:fixed;top:0;right:0;bottom:0;left:0;background:rgba(0, 0, 0, 0.7);opacity:0;transition:all 500ms ease-in-out;';

            var internalElem = document.createElement('div');
            internalElem.style.cssText = 'position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);background:#fff;border-radius:5px;width:80%;max-width:1345px;height:90%;max-height:850px;padding:0px;';
            popup.appendChild(internalElem);

            var closeBtn = document.createElement('a');
            closeBtn.href = '';
            closeBtn.innerHTML = '&times;';
            closeBtn.style.cssText = '    position: absolute; top: -10px; right: -10px;    text-decoration: none;    width: 24px;    height: 24px;    font-size: 19px;    font-weight: 700;    color: rgb(0 0 0);    border-radius: 50%;    background: white;    text-align: center;    line-height: 24px;    padding-left: 1px;';
            internalElem.appendChild(closeBtn);

            closeBtn.addEventListener('click', function (event) {
                event.preventDefault();

                closePopup();
            });

            var iframe = document.createElement('iframe');
            iframe.setAttribute('allowFullScreen', '');
            iframe.allow = 'clipboard-write';

            iframe.style.cssText = 'width:100%;height:100%;border:0;border-radius: 5px;';
            internalElem.appendChild(iframe);

            document.body.appendChild(popup);
        }

        var iframe = popup.getElementsByTagName('iframe')[0];
        iframe.src = url;

        popup.style.visibility = 'visible';
        popup.style.opacity = '1';
    };

    const CatalogoBtn = (event, url) => {
        event.preventDefault();
        if (typeof window.orientation != 'undefined' || (navigator.userAgent.indexOf('IEMobile') !== -1)) {
            window.open(url, '_blank')
        }
        else {
            showPopup(url);
        }
    }

    const [showVideo1, setShowVideo1] = useState(false);
    const [showVideo2, setShowVideo2] = useState(false);

    const tocaTusSenos = async () => {
        const id = 1;
        UpdateCounter(id);
        window.open('http://tocatussenos.com/', '_blank');
    }
    const UpdateCounter = async (id) => {
        const requestOptions = {
            method: 'PATCH',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(id)
        }
        fetch("api/ketalweb/visitCounter", requestOptions);
    }
    return (
        <React.Fragment>
            {showVideo1 === true &&
                <VideoPopUp videoProp={unikos} onClose={() => setShowVideo1(false)} />
            }
            {showVideo2 === true &&
                <VideoPopUp videoProp={ketalVideo} onClose={() => setShowVideo2(false)} />
            }
            <div className="oferta-container">
                {/*<div className="ofertas-section">
                    <div className="ofertas-section-img-cont">
                        <img src={oferta1}
                            className="ofertas-section-img" />
                    </div>
                    <img src={oferta1}
                        className="ofertas-section-img-reflex" />
                </div>*/}
                {/*<div className="ofertas-section">
                    <div className="ofertas-section-img-cont">
                        <img src={oferta2}
                            className="ofertas-section-img" />
                    </div>
                    <img src={oferta2}
                        className="ofertas-section-img-reflex" />
                </div>*/}
                <a onClick={tocaTusSenos}>
                    <div className="ofertas-section">
                    <div className="ofertas-section-img-cont">
                        <img src={tocatussenos}
                            className="ofertas-section-img" />
                    </div>
                    <img src={tocatussenos}
                        className="ofertas-section-img-reflex" />
                    </div>
                </a>
                <div className="ofertas-section">
                    <div className="ofertas-section-img-cont">
                        <img src={oferta3}
                            className="ofertas-section-img" />
                        <button className="ofertas-section-3-btn" onClick={() => setShowVideo1(true) }>Ver más</button>
                    </div>
                    <img src={oferta3}
                        className="ofertas-section-img-reflex" />
                </div>
                {/*<div className="ofertas-section oferta-section-catalog-container">
                    <h1 className="ofertas-section-catalog-title">No te pierdas las ofertas del mes!</h1>
                    <p className="ofertas-section-catalog-text">Échale un vistazo a nuestros catalogos <br/> Haz click!</p>
                    <div className="oferta-section-catalog">
                        <a class="show-publuu-viewer" onClick={(e) => CatalogoBtn(e, "https://publuu.com/flip-book/258038/981365/page/1?cover")}>
                            <img src="https://p6aqvvqp5i.execute-api.us-east-2.amazonaws.com/images/cover/258038/981365" style={{ height: "100%" }}/>
                        </a>
                        <a class="show-publuu-viewer" onClick={(e) => CatalogoBtn(e, "https://publuu.com/flip-book/258038/964832/page/1?cover") }>
                            <img src="https://p6aqvvqp5i.execute-api.us-east-2.amazonaws.com/images/cover/258038/964832" style={{ height: "100%" }} />
                        </a>
                        <a class="show-publuu-viewer" onClick={(e) => CatalogoBtn(e, "https://publuu.com/flip-book/258038/947108/page/1?cover")}>
                            <img src="https://p6aqvvqp5i.execute-api.us-east-2.amazonaws.com/images/cover/258038/947108" style={{ height: "100%" }} />
                        </a>
                    </div>
                </div>*/}
                {/*<div className="ofertas-section">
                    <div className="ofertas-section-img-cont">
                        <img src={oferta4}
                            className="ofertas-section-img" />
                        <button className="ofertas-section-4-btn" onClick={() => setShowVideo2(true)}>Ver más</button>
                    </div>
                    <img src={oferta4}
                        className="ofertas-section-img-reflex" />
                </div>*/}
            </div>
        </React.Fragment>
    );
}

export default Ofertas;