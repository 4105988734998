import { useEffect, useState } from 'react'
import './PayConfirmation.css'
import ketalLogo from '../../assets/image/logo.png';

function PayConirmation() {
    const [id, setId] = useState(null);
    const [ok, setOk] = useState(true);
    const GetData = async (key) => {
        const res = await fetch("/api/ketalweb/GetPagosKindCards?key=" + key, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        });
        if (res.status === 200) {
            const data = await res.json();
            setId(data.id);
            setOk(false)
        }
        else {
            setOk(true);
        }
    };

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const keyParam = searchParams.get('key');
        GetData(keyParam);
    }, []);
    const [inputValue, setInputValue] = useState();
    const [inputMonto, setInputMonto] = useState();
    const sendValue = async (e) => {
        e.preventDefault();
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: id,
                valor: inputMonto,
                number: inputValue
            })
        }
        const res = await fetch("/api/ketalweb/SendPagoConfirm", requestOptions);
        if (res.status === 200) {
            setOk(true);
        }
    }
    const changeMontoValue = (e) => {
        setInputMonto(e)
    }
    return (
        <div className="kind-page-cont">
            <div>
                <img style={{ width: '80px' }} src={ketalLogo}></img>
            </div>
            {ok === false ? (<>
                <h3 style={{marginTop:'10px'}}>Confirmación de pago de KindCard</h3>
                <div>
                    <form onSubmit={sendValue}>
                        <p>Por favor requerimos tu autorización para realizar el pago KindCard.</p>
                        <p>Ingresa el monto autorizado:</p>
                        <div className="monto-input-container">
                            <div className="monto-input">
                                <span>Bs.</span>
                                <input type="number" value={inputMonto} onChange={e => changeMontoValue(e.target.value)}></input>
                            </div>
                        </div>
                        <p>Ingresa los últimos 4 dígitos de la tarjeta:</p>
                        <div className="value-input-kc-container">
                            <div className="value-input-kc">
                                <label>67XXXXXX</label>
                                <input value={inputValue} onChange={e => {
                                    if (e.target.value.length <= 4) {
                                        setInputValue(e.target.value)
                                    }
                                }
                                }></input>
                            </div>
                        </div>
                        <div className="kindcard-btn-panel">
                            <button type='submit' className="kindcard-btn">Enviar</button>
                        </div>
                    </form>
                </div>
            </>) : (<h3 style={{ marginTop: '10px' }}>Pago Autorizado. Gracias!</h3>)}
        </div>
    )
}

export default PayConirmation
