import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import NotifyV from './NotifyV';
import { format } from "date-fns";
import { Block } from 'notiflix/build/notiflix-block-aio';
import './Reporte.css'; 
import { useFetchRep } from './useFetchRep'; 
import { useFetchCliente } from './useFetchCliente'; 



let financial = (x) => Number.parseFloat(x).toFixed(2);
function Reporte({ }) {

    const navigate = useNavigate();
    
    const [nroDoc, setNroDoc] = useState('');
    let [mensaje, setMensaje] = useState('');
    
    const [giftCard, setGiftCard] = useState('');
    const [showNotify, setShowNotify] = useState(false);
    const [notifyvMsg, setNotifyvMsg] = useState('');
    const [data , setData]= useState([]);
    const [loading , setLoading]= useState(false);
    let [cliente , setCliente]= useState([]);
    const {getReport}=useFetchRep();
    const {getCliente} =useFetchCliente();

    const getReporte =async (e) =>{
        //alert('hola  mundo')
        setMensaje('')

        if (nroDoc === null || nroDoc === undefined || nroDoc === "") {
            setNotifyvMsg("El Numero de Documento es Necesario!!");
            setShowNotify(true);
            return ;
        }

        if (giftCard === null || giftCard === undefined || giftCard === "") {
            setNotifyvMsg("El Numero de Gift-Card es Necesario !!");
            setShowNotify(true);
            return ;
        }
        

        setLoading(true);
        const [cliente ,loadincli] =await getCliente(nroDoc);
        setLoading(loadincli);
        //console.log(cliente);
        setMensaje(cliente.msje)

       if(!(cliente.state==="OK")){
            setNotifyvMsg("No se encontro el Cliente,  por favor registrese ");
            setShowNotify(true);
            setData([])
            //console.log(cliente.state);
            return ;

       }else {
            setLoading(true);
            const [report ,loadin] =await getReport(giftCard);
            setLoading(loadin);
            setData(report);
            setMensaje(report.msje)

       }
        //Block.remove("body");
    }

    
    const DouwnloadExcel= async (e) =>{
        
        

    }
    
     


    return (
        <div className="login bg-claro" >
            {showNotify &&
                <NotifyV message={notifyvMsg} onClose={e => { setShowNotify(false)}}></NotifyV>
            }

            
            <form  style={{  minWidth:'1000px' }}>
                {loading &&  <div  className='popuRelative' style={{display:'flex', justifyContentCenter: 'center'}}>
            
                                <h3>Loading...</h3>
                                <img alt="loading"  src="loading2.gif" width="200" height="200" style={{borderRadius: '50px', marginTop:'145px' }} />   

                            </div>
                }
                <div className='card' style={{ backgroundColor:'transparent', borderRadius:'25', minWidth:'700px' }}>
                    <div className="registro-container">
                        <div className="login-title">
                            <h2>REPORTE GIFT-CARD</h2>
                        </div>
                        <div className="registro-line row">
                            <div className="registro-line col">
                                <label className="login-ketal-label">NRO. DOC:</label>
                                <input placeholder='ej. 00000' className="login-ketal-input" type="text"
                                    value={nroDoc} onChange={e => { setNroDoc(e.target.value) }} />
                            </div>
                            <div className="registro-line col">
                                <label className="login-ketal-label">NRO. GIFT-CARD:</label>
                                <input placeholder='ej. 6600000' className="login-ketal-input" type='text' 
                                    value={giftCard} onChange={e => { setGiftCard(e.target.value) }}>
                                </input>
                            </div>
                        </div>
                        <div className="col d-flex justify-content-center mb-3 mt-3">
                            <button onClick={getReporte} className="ketal-btn-login" style={{ marginTop: '0px' }} type="button">Procesar</button>
                            <button onClick={DouwnloadExcel}  className="ketal-btn-login" style={{ marginTop: '0px' }} type="button">Exportar </button>
                        </div>
                        <div className="col mb-3 mt-3">

                            { (mensaje.length>0)&&
                                <div  id="errorMsjeJs" className="sminformMsje rigth_field"   >
                                        {mensaje}
                                </div>
                            }
                            
                        </div>
                    </div>
                    
                </div>
                <div className="salto" ></div>
                    <div className="compras-table scroll-table" >
                        <table style={{  marginLeft:'auto', marginRight:'auto' }}>
                            <thead>
                                <tr>
                                <   th>#</th>
                                    <th>Nro. GiftCard</th>
                                    <th>Factura</th>
                                    <th>caja</th>
                                    <th>Sucursal</th>
                                    <th>Monto</th>
                                    <th>NIT</th>
                                    <th>R. Social</th>
                                    <th>Fecha</th>
                                    <th>Saldo Inicial</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.data?.map((gift)=>
                                <tr key={gift.nro}>
                                    <td> {gift.nro}</td>
                                    <td> {gift.numeroGIFTCard}</td>
                                    <td> {gift.factura}</td>
                                    <td> {gift.caja}</td>
                                    <td> {gift.sucursal}</td>
                                    <td style={{ textAlign:'end'}}> {financial(gift.usoGiftCardFactura)}</td>
                                    <td> {gift.nitCi}</td>
                                    <td> {gift.nombreFactura}</td>
                                    <td style={{ textAlign:'start'}}> { format(gift.fecha, "yyyy-MM-dd") }</td>
                                    <td style={{ textAlign:'end'}}> { financial(gift.saldoInicial)}</td>
                                </tr> 
                            )}
                            </tbody>
                        </table>
                    </div>
                    <div className="salto" ></div>
            </form>
        </div>
    );
}


export default Reporte;
